
const Footer = () => {
    return (
        <div className='footer'>
            © Copyright Expia 2023
        </div>
    )
}

export default Footer
