import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import './login.css';
import logo from '../assets/logo.svg'
import topleft from '../assets/top-left.svg'
import topRight from '../assets/top-right.svg'
import Footer from './Footer';

const Login = () => {
    const history = useHistory();
    const mhost = 'https://lms.expia.co.in';
    // const mhost = 'http://localhost';
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [invalidMessage, setInvalidMessage] = useState('');
    const onSubmit = async (e) => {
        try {
            const rawResponse = await fetch(mhost + ':5000/auth', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username: username, password: password })
            });
            setInvalidMessage('')
            const content = await rawResponse.json();
            // console.log(content);
            localStorage.setItem('uIUTs', content.name);
            localStorage.setItem('hj12H', content.token);
            history.push("/home");

        } catch (err) {
            console.log(err);
            setInvalidMessage('Invalid Credentials' + err)
            history.push("/");
        }
    }
    return (
        <div className='loginForm'>
            <img src={topleft} className="topLeft" alt="logo" />
            <img src={topRight} className="topRight" alt="logo" />
            <img src={logo} className="App-logo" alt="logo" />
            <input type="text" name="username" placeholder='Enter username'
                value={username}
                onChange={(e) => setUsername(e.target.value)} autoFocus autoComplete="off"></input>

            <input type="password" name="password" placeholder='Enter password'
                value={password}
                onChange={(e) => setPassword(e.target.value)} autoComplete="off"></input>
            <button name="submit" className="btnLogin" onClick={onSubmit}>Login</button>
            {invalidMessage.length > 0 && <label className="invCredentials">Invalid Credentials</label>}
            <Footer />
        </div>
    )
}

export default Login
