import Login from "./components/Login";
import Mhome from "./components/Home";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import './App.css';
function App() {
  return (
    <div className="App">
      <Router>
        <Route exact path="/home" component={Mhome} />
        <Route exact path="/" component={Login} />
      </Router>
    </div>
  );
}

export default App;
