import calendar from '../assets/calender_icon.svg'
import separator from '../assets/separator.svg'

const Holidays = () => {
    const days = [
        {
            "id": 1,
            "date": "26-Jan-2023",
            "label": "Republic Day"
        },
        {
            "id": 2,
            "date": "08-Mar-2023",
            "label": "Holi"
        },
        {
            "id": 3,
            "date": "15-Aug-2023",
            "label": "Independence Day"
        },
        {
            "id": 4,
            "date": "30-Aug-2023",
            "label": "Rakshabandhan"
        },
        {
            "id": 5,
            "date": "07-Sep-2023",
            "label": "Janmashtami"
        },
        {
            "id": 6,
            "date": "19-Sep-2023",
            "label": "Ganesh Chaturthi"
        },
        {
            "id": 7,
            "date": "02-Oct-2023",
            "label": "Gandhi Jayanti"
        },
        {
            "id": 8,
            "date": "24-Oct-2023",
            "label": "Vijay Dashami/Dussehra"
        },
        {
            "id": 9,
            "date": "13-Nov-2023",
            "label": "Diwali"
        }
    ];
    return (
        <div className='greyDiv lastRow'>
            <img src={calendar} className="calendar-logo" alt="logo" /> <h3 className='gLabel'>Public Holidays</h3>
            <br /><div className="separator" ></div>
            <table>
                <tbody>

                    <tr>
                        <th>Date</th>
                        <th>Description</th>
                    </tr>
                    {days.map(function (day, index) {
                        return <tr key={day.id}>
                            <td>{day.date}</td>
                            <td>{day.label}</td>
                        </tr>;
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default Holidays
