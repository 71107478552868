import logo from '../assets/logo.svg'
import { useHistory } from 'react-router-dom';
import logout from '../assets/logout.svg'
const Header = () => {
    const history = useHistory();
    const usr = localStorage.getItem('uIUTs')
    const logoutClick = async (e) => {
        localStorage.removeItem('uIUTs');
        localStorage.removeItem('hj12H');
        history.push("/");
    }
    return (
        <div className='header'>
             <img src={logo} className="appLogoHome" alt="logo" />
             <ul className='topMenu'>
                 <li>Welcome, {usr}</li>
                 <li><img src={logout} className="logoutBtn" alt="logout" onClick={logoutClick} /></li>
             </ul>
             
        </div>
    )
}

export default Header
