import React from 'react'
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react'
import Moment from 'moment'
import Header from './Header';
import Holidays from './Holidays';
import historyIco from '../assets/history.svg'
import balance from '../assets/balance.svg'
import topleft from '../assets/top-left.svg'
import topRight from '../assets/top-right.svg'
import loader from '../assets/loader.svg'
import Footer from './Footer';
const Home = () => {
    let [LeaveBalance, setLeaveBalance] = useState([]);
    let [LeaveHistory, setLeaveHistory] = useState([]);
    let [historyLoading, setHistoryLoading] = useState(true);
    let [leaveLoading, setLeaveLoading] = useState(true);
    const history = useHistory();
    const mhost = 'https://lms.expia.co.in';
    // const mhost = 'http://localhost';
    useEffect(() => {
        const fetchLeaves = async () => {
            try {
                const options = {
                    headers: new Headers({ 'Authorization': localStorage.getItem('hj12H') })
                };
                const res = await fetch(mhost + ':5000/emps/bal', options)
                const data = await res.json();
                setLeaveBalance(data.data);
                setLeaveLoading(false);

            } catch (error) {
                history.push("/");
            }
        }
        const fetchLeaveList = async () => {
            try {
                const options = {
                    headers: new Headers({ 'Authorization': localStorage.getItem('hj12H') })
                };
                const res = await fetch(mhost + ':5000/emps/leaves', options)
                const data = await res.json();
                setLeaveHistory(data.data);
                setHistoryLoading(false);
            } catch (error) {
                history.push("/");
            }
        }
        fetchLeaveList();
        fetchLeaves();
    }, [localStorage.getItem('hj12H')])
    return (
        <div className='container'>
            <Header />
            <div className="row">


                <div className='col-md-8'>
                    <div className='greyDiv'>
                        <img src={historyIco} className="calendar-logo" alt="logo" /> <h3 className='gLabel'>Leave History</h3>
                        <br /><div className="separator" ></div>
                        <img src={loader} className="loader" alt="logo" style={{ display: historyLoading ? 'block' : 'none' }} />
                        <table>
                            <tbody>
                                {LeaveHistory.length > 0 &&
                                    <tr>
                                        <th>Leave type</th>
                                        <th>From Date</th>
                                        <th>To Date</th>
                                    </tr>
                                }
                                {!historyLoading && LeaveHistory.length == 0 && <tr> <td> You haven't applied for leaves yet</td></tr>}
                                {LeaveHistory.sort((a, b) => a.from_leave < b.from_leave ? 1 : -1).map(function (lh, index) {
                                    return <tr key={lh.id}>
                                        <td style={{ textTransform: 'capitalize' }}>{lh.leave_type + ' Leave'} {lh.full_half != 1 && '(half day)'}</td>
                                        <td>{Moment(lh.from_leave).format('DD-MMM-YYYY')}</td>
                                        <td>{(lh.from_leave === lh.to_leave) ? '-' : Moment(lh.to_leave).format('DD-MMM-YYYY')}</td>
                                    </tr>;
                                })}

                            </tbody>
                        </table>
                    </div>
                    <Holidays />
                </div>
                <div className=" col-md-4">
                    <div className="greyDiv lastRow lastRowMob">
                        <img src={balance} className="calendar-logo" alt="logo" /> <h3 className='gLabel'>Leave Balance</h3>
                        <br /><div className="separator" ></div>
                        <img src={loader} className="loader" alt="logo" style={{ display: leaveLoading ? 'block' : 'none' }} />
                        {!leaveLoading &&
                            <table>
                                <tbody>

                                    <tr>
                                        <th>Leave type</th>
                                        <th>Balance</th>
                                    </tr>
                                    {LeaveBalance.map(function (leaveBalance, index) {
                                        return <tr key={leaveBalance.id}> <td style={{ textTransform: 'capitalize' }}>{leaveBalance.leave_type + ' Leave'}</td> <td>{leaveBalance.fbalance}</td></tr>;
                                    })}
                                </tbody>
                            </table>
                        }
                        <a href="https://forms.gle/56YKFLDbtYd32GbW8" className='applyLeave' target='_blank'>Apply for Leave</a>
                    </div>
                </div>
            </div>


            <img src={topleft} className="topLeft blur" alt="logo" />
            <img src={topRight} className="topRight blur" alt="logo" />
            <Footer />
        </div>
    )
}

export default Home
